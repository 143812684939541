import slugify from '@sindresorhus/slugify'
import type { IconName, IconPrefix } from '@shc/ui/dist/components/Icon/Icon'

type IconContentType = 'icon' | 'iconDuotone' | 'iconBrand' | 'iconCustom'

const typeToPrefix: Record<IconContentType, IconPrefix> = {
  icon: 'fas',
  iconDuotone: 'fad',
  iconBrand: 'fab',
  iconCustom: 'fak',
}

export const getIcon = (iconName?: string, type?: IconContentType) => {
  if (!iconName) {
    return null
  }
  const name = slugify(iconName)
  if (!type) {
    return name as IconName
  }
  return [typeToPrefix[type] ?? 'fas', name] as [IconPrefix, IconName]
}
