'use client'
import { useState } from 'react'
import { type MouseEvent } from 'react'
import NewsletterSignUp from '@components/newsletter-signup'
import useAnalytics from '@hooks/use-analytics'
import { Button } from '@shc/ui'

function FooterNewsletter() {
  const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false)
  const { track } = useAnalytics()

  const click = (e: MouseEvent<any>) => {
    setIsNewsletterModalOpen(true)
    track({
      event: { name: 'modal_open', data: {} },
      contexts: [
        { name: 'section', data: { section_name: 'footer' } },
        {
          name: 'component',
          data: {
            component_text: e.currentTarget.textContent,
          },
        },
        { name: 'modal', data: { modal_name: 'Newsletter' } },
      ],
    })
  }

  return (
    <>
      <p className="font-semibold text-center">Get health tips, recipes and more in your inbox</p>
      <Button
        size="sm"
        variant="outlined"
        width="auto"
        className="mt-3 w-[240px] md:w-[200px]"
        onClick={click}>
        Subscribe
      </Button>
      <NewsletterSignUp
        isModalOpen={isNewsletterModalOpen}
        setIsModalOpen={setIsNewsletterModalOpen}
      />
    </>
  )
}

export default FooterNewsletter
