'use client'

import { getIcon } from '@components/icons'
import type { TypeAlert } from '@lib/generated-types'
import { richTextRender } from '@lib/rich-text-base'
import { Alert } from '@shc/ui'
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'
import { isMatch } from 'micromatch'

type AlertBarProps = {
  alerts?: TypeAlert[]
}

function AlertBar({ alerts }: Readonly<AlertBarProps>) {
  const pathname = usePathname()

  const alertBar = useMemo(() => {
    if (pathname && alerts) {
      for (const alert of alerts) {
        const matches = isMatch(pathname, alert.fields.includePaths ?? '/**', {
          ignore: alert.fields.excludePaths,
        })
        if (matches) {
          return alert
        }
      }
    }
    return undefined
  }, [alerts, pathname])

  if (alertBar) {
    return (
      <Alert icon={getIcon(alertBar.fields.icon?.fields.icon)}>
        <div className="alert-bar-rich-text">{richTextRender(alertBar.fields.description)}</div>
      </Alert>
    )
  }
  return null
}

export default AlertBar
