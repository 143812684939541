'use client'
import type { ComponentPropsWithoutRef } from 'react'
import { Icon, Link } from '@shc/ui'
import Analytics from '@components/analytics'
import type { SectionContext } from '@hooks/use-analytics'
import useAnalytics from '@hooks/use-analytics'

type ContactUsProps = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
  section: SectionContext['section_name']
}

const ContactUs = ({ section, ...props }: ContactUsProps) => {
  const { track } = useAnalytics()
  const contactUsLabel = 'Contact us'
  const contactUsLink = '/contact-us'
  const chatLabel = 'Chat with us'
  const chatHours = '7 am to 7 pm daily'
  const phoneLabel = '1-800-827-4277'
  const phoneHours = 'Weekdays, 7 am to 7 pm'
  const phoneLink = 'tel:+18008274277'
  const directoryLabel = 'phone directory'
  const directoryLink = '/phone-numbers.cfm'

  const popChat = () => {
    if (typeof window._uwc === 'function') {
      window._uwc('toggle')
    }

    // track button click
    track({
      event: {
        name: 'contact_us_click',
        data: {
          contact_type: 'chat',
          displayed_hours: chatHours,
        },
      },
      contexts: [
        {
          name: 'component',
          data: { component_text: chatLabel },
        },
        { name: 'section', data: { section_name: section } },
      ],
    })

    // track modal open
    track({
      event: { name: 'modal_open', data: {} },
      contexts: [
        {
          name: 'component',
          data: {
            component_text: chatLabel,
          },
        },
        { name: 'section', data: { section_name: 'footer' } },
        { name: 'modal', data: { modal_name: 'Chat with a care specialist at Sharp' } },
      ],
    })
  }
  return (
    <div {...props}>
      <h3 className="text-sm font-semibold text-gray-700">Need help?</h3>
      <ul>
        <li className="mt-3">
          <Analytics
            click={{
              name: 'contact_us_click',
              data: { contact_type: 'contact' },
            }}
            contexts={[
              {
                name: 'component',
                data: { component_text: contactUsLabel, component_url: contactUsLink },
              },
              { name: 'section', data: { section_name: section } },
            ]}>
            <Link
              href={contactUsLink}
              className="inline-flex flex-row items-center text-sm"
              noUnderline>
              <Icon icon="envelope" className="text-gray-700 pr-1.5" />
              <span>{contactUsLabel}</span>
            </Link>
          </Analytics>
        </li>
        <li className="mt-4">
          <Link
            as="button"
            className="inline-flex flex-row items-center text-sm"
            noUnderline
            onClick={popChat}>
            <Icon icon="comment" className="text-gray-700 pr-1.5" />
            <span>{chatLabel}</span>
          </Link>
          <p className="ml-5 text-sm font-medium text-gray-700">{chatHours}</p>
        </li>
        <li className="mt-4">
          <Analytics
            click={{
              name: 'contact_us_click',
              data: { contact_type: 'call', displayed_hours: phoneHours },
            }}
            contexts={[
              {
                name: 'component',
                data: { component_text: phoneLabel, component_url: phoneLink },
              },
              { name: 'section', data: { section_name: section } },
            ]}>
            <Link
              href={phoneLink}
              className="inline-flex flex-row items-center text-sm"
              noUnderline>
              <Icon icon="phone" className="text-gray-700 pr-1.5" />
              <span>{phoneLabel}</span>
            </Link>
          </Analytics>
          <p className="ml-5 text-sm font-medium text-gray-700">
            {phoneHours}
            <br />
            View{' '}
            <Analytics
              click={{
                name: 'contact_us_click',
                data: { contact_type: 'directory', displayed_hours: phoneHours },
              }}
              contexts={[
                {
                  name: 'component',
                  data: { component_text: 'phone directory', component_url: directoryLink },
                },
                { name: 'section', data: { section_name: section } },
              ]}>
              <Link href={directoryLink} className="text-sm" noUnderline>
                {directoryLabel}
              </Link>
            </Analytics>
          </p>
        </li>
      </ul>
    </div>
  )
}

export default ContactUs
